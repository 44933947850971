.divider {
  height: var(--nav-height);
}

.pagebox {
  /* height: calc(100vh - var(--nav-height)); */
  /* height: 25rem; */
  margin-top: 5vh;
  margin-bottom: 5vh;
  /* max-height: 85vh; */
  /* margin-top: 15vh; */
  /* border: 1px solid red; */
  padding-left: 2.5%;
  padding-right: 2.5%;
  /* overflow: hidden; */
  /* min-height: 25rem; */
  /* height: 25rem; */
  min-height: 600px;
}
