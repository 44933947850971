.project-card {
  border-radius: 8px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 12px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 1.5rem;
}

.project-card:hover {
  box-shadow: 15px 15px 12px rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .project-card {
    width: auto;
  }
}

@media (min-width: 769px) {
  .project-card {
    width: 100%;
  }
}
