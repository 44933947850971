.grid-container {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 2rem;
  margin-top: 2.5%;
}

.grid-item {
  height: 100%;
  /* width: 300px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 769px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) {
  .grid-item {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .grid-item {
    flex-direction: column;
  }
}
