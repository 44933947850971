.main-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  height: 25vw;
  border-radius: 8px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 12px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 300px;
  overflow: hidden;
}

.experience-titles {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 33%;
}

.exp-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 33%;
}

.exp-title:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.vl {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  height: 90%;
}

.experience-content {
  margin-left: 5%;
  margin-top: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  width: 80%;
}

@media (min-width: 769px) {
  .main-card {
    flex-direction: row;
    width: 50vw;
  }
  .experience-titles {
    flex-direction: column;
  }
  .exp-title {
    height: 33%;
  }
  .vl {
    height: 90%;
  }
}

@media (max-width: 768px) {
  .main-card {
    flex-direction: column;
    width: 80vw;
    height: 60vh;
  }
  .experience-titles {
    flex-direction: row;
    width: 100%;
    height: 25%;
  }
  .exp-title {
    height: 100%;
    font-size: 1rem;
  }
  .vl {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    height: 1px;
  }
  .experience-content {
    height: 75%;
    overflow: scroll;
  }
}
