.navbar {
  border-radius: 8px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 12px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* margin: 2rem 4rem;
  padding: 1rem 4rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* height: 10vh; */
  height: calc(var(--nav-height) * (2 / 3));
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
  /* padding: 1rem 4rem; */
  padding-left: 5%;
  padding-right: 5%;
  margin-top: calc(var(--nav-height) * (1 / 3));
  /* margin-top: 5vh; */
  /* margin-bottom: 5vh; */
  top: 0;
  /* margin: 5vh 0 5vh 0; */
  /* margin-right: 20%; */
  /* max-width: 100vw; */
  position: fixed;
  /* padding-left: 4rem;
  padding-right: 4rem; */
  /* background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  margin: 2rem 2rem 2rem 2rem;
  padding: 1rem 1rem 1rem 1rem; */
  /* min-height: 60px; */
  z-index: 100;
}

.navlinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  /* padding-left: 4rem;
    padding-right: 4rem; */
}
